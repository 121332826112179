import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuToggle,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { menu } from "ionicons/icons";
import { CategorieLinks } from "../../components/CategorieLinks/CategorieLinks";
import PictureCard from "../Pictures/PictureCard";
import { useCommon } from "../../common/useCommon";
import { usePictures } from "../Pictures/usePictures";
import ToolbarIcons from "../../components/ToolbarIcons/ToolbarIcons";
import { ADDITIONAL_EQUIPMENT_CATEGORY } from "../../common/constants";
import PicturePreview from "../Pictures/PicturePreview";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "@ionic/react/css/ionic-swiper.css";
import { useEffect } from "react";

interface PictureProps {
  big: string;
  small: string;
}

const Picture: React.FC<PictureProps> = ({ small, big }) => (
  <picture>
    <source media="(min-width:650px)" srcSet={`assets/img/start/${big}`} />
    <img
      src={`assets/img/start/${small}`}
      alt="Bojenje po brojevima"
      style={{ width: "100%" }}
    />
  </picture>
);

const Start = () => {
  const { zoom } = useCommon();
  const { allPictures, getAllPictures } = usePictures();

  useEffect(() => {
    if (allPictures.length === 0) {
      getAllPictures();
    }
  }, []);

  const picturesFiltered = () =>
    allPictures.filter((p) => p.category !== ADDITIONAL_EQUIPMENT_CATEGORY);

  return (
    <IonPage id="start-page">
      <IonHeader no-border>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuToggle>
              <IonIcon icon={menu} style={{ fontSize: 28 }} />
            </IonMenuToggle>
          </IonButtons>
          <IonTitle>Bojenje po brojevima</IonTitle>
          <ToolbarIcons />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol size="12" sizeMd="6" offsetMd="3">
              <img src="assets/BPB_logo.png" alt="" className="slide-image" />
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol size="12" sizeMd="8" offsetMd="2">
              <IonCard>
                <IonCardHeader className="b f15 ion-text-center">
                  Dobrodošli u šareni svet
                </IonCardHeader>
                <IonCardContent>
                  <Swiper
                    modules={[Autoplay, Navigation]}
                    autoplay={true}
                    navigation
                    // onSwiper={(swiper: unknown) => console.log(swiper)}
                  >
                    <SwiperSlide>
                      <Picture
                        small="bpb_start_01_small.jpg"
                        big="bpb_start_01.jpg"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Picture
                        small="bpb_start_03_small.jpg"
                        big="bpb_start_03.jpg"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Picture
                        small="bpb_start_02_small.jpg"
                        big="bpb_start_02.jpg"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Picture
                        small="bpb_start_04_small.jpg"
                        big="bpb_start_04.jpg"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Picture
                        small="bpb_start_05_small.jpg"
                        big="bpb_start_05.jpg"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Picture
                        small="bpb_start_07_small.jpg"
                        big="bpb_start_07.jpg"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Picture
                        small="bpb_start_06_small.jpg"
                        big="bpb_start_06.jpg"
                      />
                    </SwiperSlide>
                  </Swiper>

                  <CategorieLinks />
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
        <PicturePreview />

        <IonGrid className="ion-no-padding">
          <IonRow>
            {picturesFiltered().map((pic) => (
              <IonCol
                key={pic.id}
                sizeSm={(12 / zoom).toString()}
                sizeXs={(12 / (zoom > 2 ? 2 : zoom)).toString()}
              >
                <PictureCard pic={pic} />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Start;
