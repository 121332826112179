import { FC } from "react";

interface Props {
  css?: object;
  props?: object;
}

export const Flex: FC<Props> = ({ children, css, props }) => (
  <div style={{ display: "flex", ...css }} {...props}>
    {children}
  </div>
);
